<template>
  <div class="container-fluid mt-3">
    <div class="d-flex flex-wrap mb-1 align-items-center justify-content-between">
      <Breadcrumb current_page="الأماكن الإعلانية"/>

      <router-link class="ms-2  el-button el-button--success el-button--default btn-add" to="/add-place">
        إضافة مكان إعلاني جديد
      </router-link>
    </div>
    <el-card class="mt-2">
      <div v-if="!loading">
        <el-table id="top-table" :data="placesList" class="mb-4" stripe style="width: 100%"
                  max-height="850" v-loading="loading">

          <el-table-column prop="id" class-name="px-3" label="الترتيب" width="100"/>
          <el-table-column prop="name" label="اسم المكان الإعلاني" width="250"/>
          <el-table-column prop="identifier" label="المعرف الفريد" width="160"/>
          <el-table-column prop="height" label="الطول"/>
          <el-table-column prop="width" label="العرض"/>
          <el-table-column prop="type" label="النوع"/>

          <el-table-column label="الأدوات" width="220" class-name="control-btns">
            <template #default="scope">
              <el-tooltip class="item" effect="dark" content="تعديل الإعلان" placement="top">
                <router-link :to="`/edit-place/${scope.row.id}`" class="py-1 px-2 btn btn-outline-primary">
                  <i class="far fa-edit"/>
                </router-link>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <Loader v-else/>
    </el-card>

  </div>
</template>

<script>
import Loader from "@/components/Loader";
import Breadcrumb from "@/components/Breadcrumb";
import {getPlaces} from "@/services/ads";

export default {
  name: "Ads_places",
  data()
  {
    return {
      loading   : true,
      placesList: []
    }
  },
  components: {
    Loader,
    Breadcrumb
  },
  mounted()
  {
    this.fetchAdsPlaces()
  },
  methods: {
    fetchAdsPlaces()
    {
      this.loading = true
      getPlaces()
          .then(res => {
            this.loading    = false
            this.placesList = res.data
          })
          .catch(() => this.loading = false)
    },

  }
}
</script>
